<template>
  <signature
    :receiverName="receiverName"
    @sign-event="signEvent"
    @signreturn-event="signreturnEvent"
    v-if="signShow"
  ></signature>
  <div class="simpleSignature" v-show="!signShow">
    <HeaderTit> 手写签名 </HeaderTit>
    <div class="content">
      <div class="item-textarea none-mobile">
        <div class="sign-tit">
          <span class="requireds" style="padding-left: 15px">*</span>
          <span class="item-left">当事人(签章):</span>
        </div>
        <div style="padding-top: 18px" class="signs">
          <img v-if="isHavingImg" :src="signSrc" :width="290" :height="91" />
          <sign v-else ref="sign" :width="200" :height="150"></sign>
        </div>
      </div>

      <div class="item-textarea none-pc">
        <div class="sign-tit">
          <span class="requireds" style="padding-left: 15px">*</span>
          <span class="item-left">当事人(签章):</span>
          <el-button type="primary" size="mini" @click="signShowFun"
            >前往签名</el-button
          >
        </div>
        <div style="padding-top: 18px" class="signs">
          <img v-if="isHavingImg" :src="signSrc" :width="290" :height="91" />
          <div
            v-if="!isHavingImg && !signImgStr"
            @click="signShowFun"
            class="clickSigns"
          >
            点击签名
          </div>
          <img
            v-if="!isHavingImg && signImgStr"
            :src="signImgStr"
            alt=""
            :width="290"
            :height="91"
          />
        </div>
      </div>
      <div style="text-align: right" class="clear none-mobile">
        <el-button type="primary" v-if="!isHavingImg" @click="clearFun">
          清空
        </el-button>
        <el-button type="primary" v-else @click="isHavingImg = false"
          >重写</el-button
        >
      </div>
    </div>

    <div class="btn">
      <el-button type="primary" class="btnSubmit" @click="submitFun"
        >提交</el-button
      >
    </div>
  </div>
</template>
<script>
import sign from "@/components/sign";
import signature from "./signature.vue";
import AxiosAdapter from "../request/AxiosAdapter";
import { useRouter, useRoute } from "vue-router";
import { defineComponent, onMounted, ref, computed, onUnmounted } from "vue";
import { async } from "../commons/Utils";
import { throwCustomFunction } from "../commons/ErrorHandle";
import HeaderTit from "@/components/HeaderTit.vue";
import { getRecordId } from "../commons/Utils";

export default defineComponent({
  name: "SimpleSignature",
  components: {
    sign,
    signature,
    HeaderTit,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const isHavingImg = ref(false);
    const signSrc = ref();
    const sign = ref();
    const signShow = ref(false);
    const signImgStr = ref();
    const receiverName = ref();
    getRecordId(route.query.id, "进入简易送达签章页面", true);
    const getIdentityPhotoFun = async () => {
      const data = await AxiosAdapter.get({
        url: "/webElectronic/getIdentityPhoto",
        data: { electronicId: route.query.id },
      });
      if (data.signature) {
        signSrc.value = process.env.VUE_APP_BASE_API + data.signature;
        isHavingImg.value = true;
      }
    };
    function getClientWidthFun() {
      return document.documentElement.clientWidth;
    }
    const submitFun = async () => {
      if (getClientWidthFun() >= 768) {
        if (
          !isHavingImg.value &&
          (!sign.value.linen || sign.value.linen.length < 60)
        ) {
          throwCustomFunction("签名不合法");
        }
      }
      if (getClientWidthFun() < 768) {
        if (!isHavingImg.value && !signImgStr.value) {
          throwCustomFunction("签名不合法");
        }
      }

      if (!route.query.id) {
        throwCustomFunction("电子id不存在");
      }

      await AxiosAdapter.post({
        url: "/webElectronic/simpleElectronic",
        data: {
          electronicId: route.query.id,
          signImgStr: !isHavingImg.value
            ? getClientWidthFun() < 768
              ? signImgStr.value
              : sign.value.submit(80, 50)
            : "",
        },
      });

      router.push({
        path: "/documents-detail",
        query: {
          id: route.query.id,
          caseNumberWord: route.query.caseNumberWord,
        },
      });
    };

    const signShowFun = () => {
      signShow.value = true;
    };

    const clearFun = async () => {
      sign.value.clear();
    };

    //签名回退
    const signreturnEvent = () => {
      signShow.value = false;
    };

    //获取签名base64
    const signEvent = (e) => {
      isHavingImg.value = false;
      signShow.value = false;
      signImgStr.value = e;
    };
    onMounted(() => {
      getIdentityPhotoFun();
      if (route.query.receiverName) {
        receiverName.value = route.query.receiverName;
      }
    });
    return {
      getIdentityPhotoFun,
      isHavingImg,
      signSrc,
      sign,
      submitFun,
      clearFun,
      signShow,
      signShowFun,
      signreturnEvent,
      signEvent,
      signImgStr,
      receiverName,
      getClientWidthFun,
    };
  },
});
</script>
<style  lang="scss" scoped>
.item-textarea {
  padding-top: 20px;
}
.item-left {
  font-size: 16px;
  font-weight: bold;
}
@media screen and (min-width: 768px) {
  .clear {
    width: 600px;
    margin: 0 auto;
    text-align: right;
  }
  .signs {
    position: relative;
    left: 50%;
    width: 600px;
    margin-left: -300px;
  }
  .sign-tit {
    width: 600px;
    margin: 0 auto;
    text-align: left;
  }
}
.clear {
  text-align: right;
}
.header {
  width: 100%;
  height: 44px;
  line-height: 44px;
  color: white;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  position: relative;
  background: #45b2ef;
}
.go {
  width: 8px;
  height: 16px;
  position: absolute;
  left: 25px;
  top: 14px;
}
.content {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.requireds {
  color: #ff0000;
  padding-right: 1px;
}

.btn {
  text-align: center;
  padding-top: 9px;
  padding-bottom: 33px;
}
.btn button {
  width: 253px;
  height: 37px;
  border-radius: 18px;
  background: #45b2ef;
  font-size: 13px;
  color: #fff;
}
.clickSigns {
  width: 170px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border-radius: 10px;
  background: #f4f4f4;
  color: #999;
}
</style>